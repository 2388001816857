<template>
    <div class="con-wrap">
        <CarrotTitle title="전임교수(MI) 휴가 수정">
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 강사명</th>
                                <td>
                                    <select class="w-400px" v-model="mod.info.idx_tutor">
                                        <option value="">선택</option>
                                        <option :value="irow.idx" v-for="(irow, i) in comp.tutorList" :key="i">{{ irow.ename }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 구분</th>
                                <td>
                                    <label><input type="radio" v-model="mod.info.vtype" value="full"><span class="ml-5 mr-20"> 연차</span></label>
                                    <label><input type="radio" v-model="mod.info.vtype" value="am"><span class="ml-5 mr-20"> 오전 반차</span></label>
                                    <label><input type="radio" v-model="mod.info.vtype" value="pm"><span class="ml-5 mr-20"> 오후 반차</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기간</th>
                                <td>
                                    <CarrotDatePicker v-model.sync="mod.info.sdate" class="inline-block"></CarrotDatePicker>
                                    ~ 
                                    <CarrotDatePicker v-model.sync="mod.info.edate" class="inline-block"></CarrotDatePicker>
                                </td>
                            </tr>
                            <tr>
                                <th>비고</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.title" class="w-100per" maxlength="50">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-30">
                        <button @click="mod.doCancel" class="btn-default float-left ">취소</button>
                        <button @click="mod.doDelete" class="btn-default float-left ml-10">삭제</button>
                        <button @click="mod.doSubmit" class="btn-default float-right ">저장</button>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';


export default {
    layout:"ResourceManagement",
    components: {
        CarrotDatePicker
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast  = useToast()
        const store  = useStore();

        const mod = reactive({
            is_loaded : false,

            idx : 0,

            info : {
                idx_tutor    : "",
                daytype      : "today",
                tdate        : "",
                sdate        : "",
                edate        : "",
                stime        : "07:00",
                etime        : "07:00",
                days         : [],
                title        : "",
            },

            doCancel: () => {
                router.push({ name:"ResourceManagement-MIScheduleDate" });
            },

            doDelete : () => {
                Swal.fire({
                    title : '전임교수(MI) 휴가 삭제',
                    text : '삭제하시겠습니까?',
                    showCancelButton: true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx   : mod.idx,
                            dtype : "this",
                            tdate : ""
                        };
                        axios.post('/rest/resourceManagement/deleteMISchedule', params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.info("삭제되었습니다.");
                                mod.doCancel();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx : mod.idx,
                    idx_tutor    : mod.info.idx_tutor,
                    sdate        : mod.info.sdate,
                    edate        : mod.info.edate,
                    vtype        : mod.info.vtype,
                    stime        : mod.info.stime,
                    etime        : mod.info.etime,
                    sday         : mod.info.days,
                    title        : mod.info.title,
                };

                if( !params.idx_tutor ){
                    toast.error("강사를 선택하세요.");
                    return;
                }
                if( !params.vtype ){
                    toast.error("구분을 선택하세요.");
                    return;
                }
                if( !params.sdate || !params.edate ){
                    toast.error("기간을 선택하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/modVacation", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '전임교수(MI) 휴가 수정',
                            text : '수정하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-MIScheduleDate'
                            })
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };
                axios.get('/rest/resourceManagement/getMIScheduleInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        if( mod.info.sdate == mod.info.edate ){
                            mod.info.daytype = 'today';
                            mod.info.tdate   = mod.info.sdate;
                            mod.info.sdate   = "";
                            mod.info.edate   = "";
                        } else {
                            mod.info.daytype = 'period';
                        }

                        mod.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
        });

        const comp = reactive({
            tutorList: [],
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],

            getTutorList: () => {
                let params = {
                    page : 1,
                    rows : 1000
                };
                axios.get('/rest/resourceManagement/getTutorList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.tutorList = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            changeDaytype : () => {
                if( mod.info.daytype == 'today' ){
                    mod.info.sdate = '';
                    mod.info.edate = '';
                } else {
                    mod.info.tdate = '';
                }
                mod.info.days = [];
            },

            changeDate: () => {
                mod.info.days = [];
                let td = new Date(mod.info.tdate);
                let day = td.getDay();
                if( day == 0 ) mod.info.days.push(comp.dayList[6])
                else mod.info.days.push(comp.dayList[day-1])
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.idx_team != 66 ) {
                router.go(-1);
                Swal.fire({
                    title : '전임교수(MI) 휴가 수정',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            mod.is_loaded = false;

            mod.idx = route.params.idx;
            if ( !mod.idx ) {
                router.go(-1);
            } 

            comp.getTutorList();
            mod.doSearch();
        });

        return {comp, mod};
    }
}
</script>

<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>